import { dehydrate } from '@tanstack/react-query';
import { getQueryClient, isMobileRequest } from 'common-nextjs';
import { useMount } from 'react-use';
import { fetchAllPageViewData, fetchPageView } from '~/api/tiles';
import Layout from '~/components/Layout';
import PageView from '~/components/legos/PageView';
import { homepagePageViewId } from '~/constants/hardcodedPageViewIds';
import { PageViewDataContext } from '~/contexts/PageViewDataContext';
import { visitedHomepage } from '~/services/analytics/events/generic';
import { CustomNextPage } from '~/typings/custom-next';
import { RailsPageView } from '~/typings/services/rails/tile';

interface Props {
  pageView?: RailsPageView;
  pageViewData?: any;
}

const Homepage: CustomNextPage<Props> = ({ pageView, pageViewData }) => {
  useMount(visitedHomepage);

  return (
    <Layout
      titleTemplate=""
      title="SidelineSwap | Buy and sell sports equipment: Hockey, Lacrosse, Baseball, Ski and more."
      container={false}
    >
      <PageViewDataContext.Provider value={{ data: pageViewData, pageView }}>
        <PageView pageView={pageView} />
      </PageViewDataContext.Provider>
    </Layout>
  );
};

Homepage.getInitialProps = async ctx => {
  const queryClient = getQueryClient();
  const pageView = await fetchPageView.call(ctx, homepagePageViewId);
  const pageViewData = await fetchAllPageViewData(
    pageView,
    isMobileRequest(ctx),
    {},
    queryClient,
  );

  return {
    pageView,
    pageViewData,
    dehydratedState: dehydrate(queryClient),
  };
};

// noinspection JSUnusedGlobalSymbols
export default Homepage;
